enum AppStore {
  android,
  ios,
  amazon,
}

interface AppStoreProps {
  type: AppStore
  src: string
}

class AndroidStore implements AppStoreProps {
  constructor(
    public type: AppStore = AppStore.android,
    public src: string = "/button_googleplay.png"
  ) {}
}

class AppleStore implements AppStoreProps {
  constructor(
    public type: AppStore = AppStore.ios,
    public src: string = "/button_appstore.png"
  ) {}
}

class AmazonStore implements AppStoreProps {
  constructor(
    public type: AppStore = AppStore.amazon,
    public src: string = "/button_amazon.svg"
  ) {}
}

export function getImgPath(src: string) {
  return `/imgs/${src}.jpg`
}

export function getIconPath(src: string) {
  return `/imgs/${src}_icon.png`
}

export interface LogoProps {
  store: AppStoreProps
  link: string
}

export const aboutBannerImg: string = "/imgs/hog_journey.jpg"
export const aboutBannerHeight: string = "40vw"
export const contactUsBannerImg: string = "/imgs/word_usa.jpg"

export type AppInfoProps = {
  title: string
  subtitle: string
  shortDescription: string
  description: string
  src: string // img src
  stores: Array<LogoProps>
  tag: string // link to /app/${tag}
  bgDescription: string // the color of the bg of the description particularly used in the AppInfoGrid and AppDetails components
  isHeadline: boolean // when it's true, it will be shown in the index headline as well as /apps headline
  isBottomBanner: boolean
  trailer?: string | undefined // youtube trailer link, set as undefined if there is no trailer for the app
  priority: number
}

export const appInfoModels: AppInfoProps[] = [
  {
    title: "KIDS LEARNING PUZZLE PIC GAME",
    subtitle: "Fun Learning App for Kids 4 – 9 Years Old",
    shortDescription:
      "Child Development Specialists Show Children Playing with Puzzles Improves Cognitive Development! Teacher Approved app for Kids!",
    description: `
    Elementary and Preschool Educators recommend Puzzle games for Mental Exercise, Cognitive Development, Improved Visual-Spatial Reasoning, Improve Memory, Greater Attention to Details, and Improved Problem-Solving Ability.<br><br>
    “Teacher Approved” app for Kids. Teacher Stamp of Approval (GooglePlay):<br>
    •	Appropriate for Ages up to 12<br>
    •	Fun and Engaging -  Popular Topic, Lots to Do, Challenges<br>
    •	Thoughtfully Designed for Children - Words and Sounds, Ease of Use, Art and Animation<br>
    •	Creativity and Imagination - Critical Thinking<br>
    •	Positive Messages - Love of Learning<br><br>
    Children playing with Puzzles helps them to better understand how themes work together and fit into the world around them.<br><br>
    Game has Hidden Object Puzzles, Memory Matching, Combo Words and Same Sounds, and Exciting Mini Games! Engaging and Educational Learning App!<br><br>
    Children will have Fun playing this app, and also Increase Awareness to Details, Advance Vocabulary, Teach Reliable Work Habits and Determination, Promote Self-Confidence and Boost Cognitive Development.<br><br>
    Popular early learning Preschool, Kindergarten, First Grade and Second Grade games from 4 – 9 years old! Little kids are entertained and learning at the same time.<br><br>
    Kids can self-play and follow easy auditory and visual instructions. App is designed for kids to play Independently or with Family and Friends.<br><br>
    The game will help children to develop Creative Thinking, Attention, Observation, Visual Memory, Fine Motor Skills, Focus and Discipline both Independently and Successfully.<br><br>
    Features:<br>
    Improve Memory<br>
    Advance Vocabulary<br>
    Improve Problem Solving Ability<br>
    Greater Attention to Details<br>
    Promote Self-Confidence<br>
    Compound Words<br>
    Sight Words<br>
    Sounds/Phonics<br>
    Practice Words Letters<br>
    Plural Words<br>
    Multiple Meaning Words<br>
    Shapes<br>
    Numbers<br>
    ABC’s<br>
    Auditory and Visual Cues<br>
    Common Preschool, Kindergarten, 1st and 2nd Grade words<br>
    Recognition and Deduction Skills<br>
    Fun and Engaging<br>
    Bonus rounds<br>
    Fun Mini-Games<br>
    Earn Coins and Stickers<br>
    Fun for parents and educators, too<br>
    `,
    src: "kids_learning",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.brainfull.hokidspuzzles",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/gr/app/kids-learning-puzzle-pic-game/id1572051529",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/dp/B09BDXTJ58/ref=sr_1_116?_encoding=UTF8&dchild=1&qid=1627967221&refinements=p_4%3ADetention+Apps&s=mobile-apps&sr=1-116",
      },
    ],
    tag: "kids-learning",
    bgDescription: "bg-blue-500",
    isHeadline: true,
    isBottomBanner: false,
    priority: 0,
  },
  {
    title: "Word Snap – Fun Words Pic Game",
    subtitle: "Addictive Picture Puzzle Brain Challenge",
    shortDescription: `Fun and Addictive Picture Word Guessing game developed by lovers of Brain games and Word games!\n
    Mind Puzzles for All Ages & Families!
    `,
    description: `Fun and Addictive Picture Word Guessing game developed by lovers of Brain games and Word games!<br><br>
    Mind Puzzles for All Ages & Families!<br><br>
    Guess Words from Photos and Pictures! 2 Pics, 1 Word! Same Sound with 1 Pic, but Different Word Meaning!<br><br>
    Guess the word combos using pictures. Fans of Brain Games, Word Games, and Puzzle games will love Word Snap!<br><br>
    WordSnap is a must have Brain Game! Get your brain power ready with a Warm Up of pics that combine to create a word. Zoom in on Pics! No Time Limits!<br><br>
    Move onto Combo Words, where two pics make one word puzzle or phrase. For example, see pics of DOG and HOUSE: combine the photos to solve for DOGHOUSE. 2 Pics, 1 Word!<br><br>
    If you can handle the challenge, Same Sound will test your brain skills where you “read” one pic that is said or spelled the same way as another word, but the puzzle word you solve has a different meaning. Example- see photo of a FLOWER: solve the puzzle by spelling FLOUR.<br><br>
    Word Snap have you scratching your head? 1 Pic, 1 Word! Same Sound is addictive for girls and boys of all ages! FREE Hints!<br><br>
    Fun word challenges for trivia puzzle fans!<br><br>
    WORD SNAP features:<br><br>
    Word Guessing Games for All Ages<br>
    Mind Puzzles with Challenging Levels<br>
    Warm Up your Puzzle Skills with 2 Pics, 1 Word<br>
    Combine Word Photos to form a Word Puzzle<br>
    Addicting and Intense with Same Sound being the Most Challenging Puzzle Level<br>
    Same Sound with 1 Pic, but a Different Word Meaning<br>
    Earn Coins and Bonus Coins<br>
    Tons of Picture Guessing Puzzles<br>
    Unscramble Letters to Form Words<br>
    Word Puzzles Updated All the Time with More Addicting Brain Teasers<br>
    No Time Limits, Zoom Feature, Skip Puzzle<br>
    Hints, Remove Letter, Reveal Word!<br>`,
    src: "word_snap",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "http://play.google.com/store/apps/details?id=com.purplejam.wordsnap",
      },
      {
        store: new AppleStore(),
        link:
          "http://apps.apple.com/us/app/word-snap-brain-pic-games/id1439698036",
      },
      {
        store: new AmazonStore(),
        link:
          "http://www.amazon.com/Word-Snap-Brain-Game-Guessing/dp/B08DMBLBZ5/ref=sr_1_30?_encoding=UTF8&dchild=1&qid=1598566864&refinements=p_4%3ADetention+Apps&s=mobile-apps&sr=1-30",
      },
    ],
    tag: "word-snap",
    bgDescription: "bg-blue-600",
    trailer: "https://www.youtube.com/embed/LxppDy0aiqc",
    isHeadline: true,
    isBottomBanner: false,
    priority: 1,
  },
  {
    title: "POP IT FIDGET FLYER",
    subtitle: "1st App to Combine Flying & Bubble Popping Fun!",
    shortDescription: `Bring FUN and JOY to your life! Perfect De-Stress Tool and Oddly Satisfying for the Brain!`,
    description: `Bring FUN and JOY to your life! Perfect De-Stress Tool and Oddly Satisfying for the Brain!<br><br>
    Improve Concentration. Feel Stress Relief. ASMR Experience. Chill and Relax. 4 Adorable, Pet Characters and Special Gold Character. Easy or Hard Flying Worlds.<br><br>
    When Bubble Dimples are all Popped in a row, that row disappears. This lets your Pet Fly straight through without hitting a Fidget Toy tower. Easy, Breezy Sailing!<br><br>
    Colorful, Happy Fidget Toys to Pop Dimples Away for anxiety relief! Collect Gold Dimple Coins! Unlock Laser Eyes to Destroy Inflatable Cupcakes!
    `,
    src: "pop_it_fidget",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.brainfull.popitfidgetflyer",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/pop-it-fidget-flyer-3d-toys/id1597600140",
      },
    ],
    tag: "pop-it-fidget-flyer",
    bgDescription: "bg-blue-500",
    isHeadline: true,
    isBottomBanner: true,
    priority: -1,
  },
  {
    title: "Hidden Objects Photo Journey",
    subtitle: "MEGA Hidden Objects Brain Puzzle",
    shortDescription: `50+ Unique Hidden Objects games All in One MEGA App!`,
    description: `
    50+ Unique Hidden Objects games All in One MEGA App! 1000’s of Hidden Objects in Real Photos Celebrating Famous USA Cities, Holidays, Animals, Food, Fun and Travel! 1000’s of Levels! MEGA Brain Adventure in One Game!<br><br>
    Spot and Find while traveling to New York, San Francisco, Hawaii, Las Vegas, LA, Florida, Chicago, Washington DC, Seattle, Beverly Hills, Maui, more! Spy objects in Christmas, Halloween, Easter, New Year’s, Valentine’s Day and St. Patrick’s Day pics.<br><br>
    Favorite USA Cities Beautifully designed in one Hidden Objects game. Exciting Adventure with a Variety of Game Play from Animals to Food & Airports to Amusement Parks. A Fun Photo Journey!<br><br>
    Features:<br>
    Thousands of Levels<br>
    Zoom to Find Objects<br>
    New Hidden Object Themes Being Added<br>
    Hints and Timed & Untimed Challenges<br>
    Drag and Zoom Scene to Search for Objects<br>
    Touch Backgrounds to Move Up and Down<br>
    Missing Letters, Scrambled Words and Bonus Objects (words highlighted in green)<br>
    Find Objects by Words or Pictures<br>
    Zoom on Pics<br>
    Real Photos with 1000’s of Objects<br>
    3 Ways to Play – Chill, Traditional and Adventure<br>
    Artistically Crafted Levels<br><br>
    New Hidden Object Themes being added! Keep checking in for Coming Soon and New games.<br><br>
    See NYC’s Central Park, famed Hollywood sign, Miami Beach, San Francisco’s Trolley, Vegas Casinos, Hawaiian Beaches, Santa’s Workshop, Halloween Mystery Mansions, more!<br><br>
    3 Ways to Play – Chill, Traditional and Adventure! Bonus Mini-Puzzle Game! Levels vary from easy to difficult. A Fun, Object Quest App for Hidden Object, Puzzle, Spot the Difference, Seek & Find, Brain and Word Fans!<br><br>
    Brain Challenging!<br><br>
    Instructions:<br>
    Select Destination<br>
    Select 3 Ways to Play & See Game for Instructions<br>
    Successfully Complete a Destination & Choose your Next Adventure<br>
    `,
    src: "hog_journey",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.DetentionApps.HiddenObjectTravelUSAQuest",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/hidden-object-photo-journey/id1529208632",
      },
    ],
    tag: "hog-journey",
    bgDescription: "bg-teal-600",
    isHeadline: false,
    isBottomBanner: true,
    priority: 0,
  },
  {
    title: "Newborn Puppy and Mommy Dog Pet Salon Doctor",
    subtitle: "Help Mommy Dog Care for Newborn Puppies",
    shortDescription: "WOOF, WOOF! 1st INTERACTIVE PREGNANT DOG AND PUPPY APP!",
    description: `
    WOOF, WOOF! 1st INTERACTIVE PREGNANT DOG AND PUPPY APP! Help Mommy Dogs and Puppies at the Vet, Dentist, Salon, Daycare, more!\n
    100+ Doggy themed items to interact with! 8 Ways to Play! FREE mini game! Pamper your Newborn Puppy and Mom Dog! Choose from 4 mom Dogs and 5 fluffy Puppies! Rush Mommy Dog to the Vet for an Ultrasound. Twins?
    `,
    src: "puppy_care",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.newbornbabypuppymommypregnantcare",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/baby-puppy-mommy-dog-vet-care/id967884494",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/My-Newborn-Puppy-Mommy-Pregnancy/dp/B00TVLTC76",
      },
    ],
    tag: "puppy-care",
    bgDescription: "bg-pink-600",
    isHeadline: false,
    isBottomBanner: true,
    priority: 4,
  },
  {
    title: "Hidden Object New York",
    subtitle: "Travel Around NYC Finding Hidden Objects",
    shortDescription:
      "Ultimate New York City Treasure Hunt! Spy 100’s of NYC Hidden Objects.",
    description: `
    Ultimate New York City Treasure Hunt! Spy 100’s of NYC Hidden Objects. Play through 10 Different New York Neighborhoods! 40+ Levels!\n
    Tour around the Map from Central Park to Times Square. Pick Your BIG Apple World! Hidden Objects New York City FUN!
    `,
    src: "hog_ny",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.hiddenobjectsnewyorkadventure",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/hidden-object-new-york-journey/id1538623135",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Hidden-Object-New-York-City/dp/B076GYCBYS/",
      },
    ],
    tag: "hog-nyc",
    bgDescription: "bg-indigo-600",
    isHeadline: false,
    isBottomBanner: true,
    priority: 2,
  },
  {
    title: "Word Game Around the USA",
    subtitle: "Addictive Brain Puzzle App for Travel Fans!",
    shortDescription: `
    An addictive Brain Game and Puzzle Crossword for Word Game addicts! Travel the USA without Leaving Home!\n
    Play Word Connect in Cities around the USA, improving your spelling, vocabulary while sharpening your brain!\n
    `,
    description: `An addictive Brain Game and Puzzle Crossword for Word Game addicts! Travel the USA without Leaving Home!<br><br>
    Play Word Connect in Cities around the USA, improving your spelling, vocabulary while sharpening your brain!<br><br>
    Unlike other Word Puzzle Games-- you must select the correct words for the puzzle, because there are other word possibilities, but you will still earn Extra Word bonus points for the non-puzzle words. You get Rewarded either way for the correct Word answers and the Extra Word possibilities! FREE Hints if your mind is boggled up!<br><br>
    Addictive, Classic Word Game that all your friends and family are playing! Gradually begins easy and gets challenging as you get immersed in traveling the USA! Can you finish the game and visit all the best cities in the USA? Includes Over 800 Word Games in total for hours of Brain Challenges!<br><br>
    Fun Word puzzle game set amongst the top USA States & Cities like New York, Florida, Los Angeles, San Francisco, San Diego, Chicago, Las Vegas, Colorado, Washington DC, Boston, Philadelphia, New Orleans, Miami, Orlando and more! Stimulating Brain Word Puzzles!<br><br>
    Super addicting, fast paced and never boring! Crossword type fun to Escape and Travel the United States! Challenging Line Puzzle Logic Game!<br><br>
    Word Games Around the USA - Brain Puzzle Crossword features:<br>
    800+ Word Puzzles<br>
    Bonus Coins for Extra Words<br>
    Bonus Rounds in Top 12 USA Tourist Spots<br>
    Beautiful Images of the Most Famous USA Cities<br>
    Visit New York City, Orlando, Laguna Beach, Cape Cod, Boulder, Vegas, Hollywood, more!<br>
    HUGE Variety of Puzzles<br>
    Begins Easy and Gets Challenging<br>
    Brain Challenging Fun<br>
    Warm Up Levels to Marathon Levels to Bonus Rounds!<br>
    `,
    src: "word_usa",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "http://play.google.com/store/apps/details?id=com.purplejamlabs.wordusa",
      },
      {
        store: new AppleStore(),
        link:
          "http://apps.apple.com/us/app/word-travel-puzzle-brain-games/id1434939131",
      },
      {
        store: new AmazonStore(),
        link:
          "http://www.amazon.com/Word-Puzzle-Game-Around-USA/dp/B08D3PQZSV/ref=sr_1_87?_encoding=UTF8&dchild=1&qid=1598566778&refinements=p_4%3ADetention+Apps&s=mobile-apps&sr=1-87",
      },
    ],
    tag: "word-usa",
    bgDescription: "bg-orange-600",
    trailer: "https://www.youtube.com/embed/qv0-pgZpfU0",
    isHeadline: false,
    isBottomBanner: true,
    priority: 3,
  },
  {
    title: "Hidden Object Christmas",
    subtitle: "Explore Holiday Hidden Object Worlds",
    shortDescription:
      "Ultimate Christmas Holiday Game! Spy 100’s of Winter & Xmas Objects.",
    description: `
    Ultimate Christmas Holiday Game! Spy 100’s of Winter & Xmas Objects. Play through 10 Different Christmas & Winter Holiday Worlds! 40+ Levels!\n
    Travel around the Xmas Map from the North Pole to Snowman Mountain. Pick Your Xmas World! Hidden Objects Christmas Celebration FUN!
    `,
    src: "hog_xmas",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.hiddenobjectchristmascelebrationpuzzle",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/hidden-object-christmas-puzzle/id1321575063",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Hidden-Object-Christmas-Celebration-Holiday/dp/B07856KTQF",
      },
    ],
    tag: "hog-xmas",
    bgDescription: "bg-red-600",
    isHeadline: false,
    isBottomBanner: true,
    priority: 6,
  },
  {
    title: "Candy Pizza Maker",
    subtitle: "Let’s Make Candy Pizza",
    shortDescription:
      "Everyone Loves Pizza and Candy, so why not combine the two into Candy Pizza Maker!",
    description: `
    Everyone Loves Pizza and Candy, so why not combine the two into Candy Pizza Maker! \n
    #1 Candy Pizza Game! 170+ Yummy Candy Pizza Toppings from Chocolate Swirls to Unicorn Faces, and Gummies to Rainbow Sprinkles!
    `,
    src: "candy_pizza",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentionapps.candydessertpizzamaker",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/candy-pizza-maker-cooking-food/id1005497572",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Candy-Chocolate-Dessert-Pizza-Maker/dp/B00ZMZQPFC/",
      },
    ],
    tag: "candy-pizza",
    bgDescription: "bg-purple-600",
    isHeadline: false,
    isBottomBanner: true,
    priority: 5,
  },
  {
    title: "Hidden Objects World Travel",
    subtitle: "MEGA Hidden Objects Brain Adventure",
    shortDescription: "40+ Different Themed Hidden Objects Games!",
    description: `
    40+ Unique Hidden Objects games All in One MEGA App! 1000’s of Hidden Objects in Real Photos Celebrating Famous USA Cities, Worldwide Travel, Holidays, Animals, Food and Adventure! 1000’s of Levels! MEGA Brain Vacation in 1 Game!\n
    Spot and Find while traveling to New York, Bora Bora, San Francisco, Italy, Hawaii, London, Las Vegas, Hong Kong, LA, Florida, Paris, Chicago, Seattle, Beverly Hills, Maui, more! Spy objects in Christmas, Halloween, Easter, New Year’s, and Valentine’s Day pics.
    `,
    src: "hog_world_travel",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.hiddenobjectsroadtripusa",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/hidden-object-travel-quest-usa/id1259328096",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Hidden-Objects-Road-Trip-USA/dp/B073X4VC3Z/",
      },
    ],
    tag: "hog-world-travel",
    bgDescription: "bg-teal-600",
    isHeadline: true,
    isBottomBanner: false,
    priority: 0,
  },
  {
    title: "Donut Maker Cooking",
    subtitle: "Let’s Make Donuts",
    shortDescription:
      "Make Donuts with Real Cooking Steps! 135+ Yummy Toppings from Icing to Candy!",
    description: `
    Make Donuts with Real Cooking Steps! 135+ Yummy Toppings from Icing to Candy!<br><br>
    Mix and Bake flavored Donuts from Rainbow to Confetti! Make Unicorn, Snowman and Smores Donuts following Recipe Cards!<br><br>
    Realistic Cooking Steps! Select Flavor, Mix Ingredients, Refrigerate, Roll Dough, Fry Donuts, Fill with Jelly, Select Glaze and Decorate! Be a Baker Chef!<br><br>
    Select Jelly filled donuts with tons of Jelly Flavors! Get ready to Decorate your Yummy Donut!<br><br>
    `,
    src: "donut_maker",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.donutmakercroissantdonutscooking",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/donut-maker-cooking-chef-fun/id990007598",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Donut-Maker-Croissant-Donuts-Cooking/dp/B00WX6FDL0/ref=sr_1_3?qid=1638514529&refinements=p_4%3ABrainfull&s=mobile-apps&search-type=ss&sr=1-3",
      },
    ],
    tag: "donut-maker",
    bgDescription: "bg-pink-600",
    isHeadline: false,
    isBottomBanner: true,
    priority: 6,
  },
  {
    title: "Baby Pet Doctor Dentist Salon",
    subtitle: "Be a Virtual Veterinarian for Baby Pets",
    shortDescription:
      "1st BABY ANIMAL VET & DENTIST APP FOR PETS! These Babies need extra TLC!",
    description: `
    1st BABY ANIMAL VET & DENTIST APP FOR PETS! These Babies need extra TLC!<br><br>
    Be a Veterinarian Doctor and Dentist with Baby Animals! Feed and Groom 6 Newborns! Help them Feel Better!<br><br>
    6 Baby Animals: Puppy Dogs, Kitty Cat, Unicorn, Panda, and Cheetah. Pets wear diapers and have pacifiers!<br><br> 
    4 Ways to Play – Doctor, Dentist, Feed and Groom! FREE Mini-Game!<br><br> 
    Select your Baby Pet and become a Vet Doctor and Dentist treating Fleas, Ticks, Rabies, Rashes, Cavities, Stinky Breath and more!<br><br> 
    Does your Puppy Dog, Boo, get the ‘Cone of Shame’? Does your Grumpy Kitty have Flea Bites? Does your Unicorn have Cavities from too much Candy? Treat your Newborn Pet to Yummy Food and Fun Bath time!
    `,
    src: "baby_pet_doctor",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.babyanimalpetvet",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/baby-pet-newborn-salon-care/id847902885",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Animal-Baby-Vet-Doctor-Hospital/dp/B00MG0TWX8/ref=mp_s_a_1_23?qid=1678236081&refinements=p_4%3ABrainfull&s=mobile-apps&search-type=ss&sr=1-23",
      },
    ],
    tag: "baby-pet-doctor",
    bgDescription: "bg-yellow-700",
    isHeadline: false,
    isBottomBanner: true,
    priority: 7,
  },
  {
    title: "Hidden Objects Summer Beach",
    subtitle: "Summer Fun Finding Objects",
    shortDescription:
      "Vacation to Beaches Around the World! Explore breathtaking Beaches in California, Florida, Italy, Hawaii, Maui, Caribbean, Mexico, Brazil and more finding hidden objects! Enjoy a Tropical Beach Vacation!",
    description: `
    Vacation to Beaches Around the World! Explore breathtaking Beaches in California, Florida, Italy, Hawaii, Maui, Caribbean, Mexico, Brazil and more finding hidden objects! Enjoy a Tropical Beach Vacation!<br><br>
    Find Hidden Objects in Summer Beaches! MEGA fun Seek and Find game with 50+ Levels! Explore Famous Destinations! Let the Brain Game begin!<br><br>
    Beautifully designed search and finder game with 50+ popular Summer Vacation themed levels. Fun items to search for in Real Scenes! A must for Hidden Object Fans! FREE MINI GAME!<br><br> 
    Discover the Excitement of Summertime without Leaving your Home. Enjoy the white, sandy beaches, gorgeous blue oceans, romantic islands, and more. Find Beach themed objects like sand toys, beach towels, sunglasses and more.<br><br> 
    This Brain Challenging adventure Around the World is sure to Entertain!<br><br>
    `,
    src: "ho_summer_beach",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.hiddenobjectssummerbeachvacation",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/hidden-objects-summer-beach/id1237410764",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Hidden-Object-Summer-Beach-Vacation/dp/B01FWLSXTG/ref=sr_1_2?qid=1678613770&refinements=p_4%3ABrainfull&s=mobile-apps&search-type=ss&sr=1-2",
      },
    ],
    tag: "ho-summer-beach",
    bgDescription: "bg-blue-700",
    isHeadline: false,
    isBottomBanner: true,
    priority: 8,
  },
  {
    title: "Hidden Objects Candy Shop",
    subtitle: "Mouth-Watering Objects Adventure",
    shortDescription:
      "MEGA fun Seek and Find game with 50+ Sweet Levels! Let the Brain Game begin! 100’s of Hidden Objects!",
    description: `
    MEGA fun Seek and Find game with 50+ Sweet Levels! Let the Brain Game begin! 100’s of Hidden Objects!<br><br> 
    Find yummy Candy, Chocolate, Ice Cream, Donuts and more! Explore Candy Stores, Chocolate Shops, Chocolate Factories, and Ice Cream Stores looking for plenty of treats!<br><br> 
    Brain Adventure through Candy & Dessert Shops is sure to Entertain!<br><br>
    `,
    src: "ho_candy_shop",
    stores: [
      {
        store: new AndroidStore(),
        link:
          "https://play.google.com/store/apps/details?id=com.detentiongames.hiddenobjectscandyshop",
      },
      {
        store: new AppleStore(),
        link:
          "https://apps.apple.com/us/app/hidden-objects-candy-shop-seek/id1013748720",
      },
      {
        store: new AmazonStore(),
        link:
          "https://www.amazon.com/Hidden-Objects-Dessert-Object-Puzzle/dp/B010O5V8ME/ref=sr_1_28?qid=1678614084&refinements=p_4%3ABrainfull&s=mobile-apps&search-type=ss&sr=1-28",
      },
    ],
    tag: "ho-candy-shop",
    bgDescription: "bg-pink-700",
    isHeadline: false,
    isBottomBanner: true,
    priority: 9,
  },
]

// TOP BANNER for both / and /apps
export const topBanners: AppInfoProps[] = appInfoModels.filter(
  x => x.isHeadline
)

// BOTTOM BANNERS in /
export const bottomBanners: AppInfoProps[] = appInfoModels
  .filter(x => x.isBottomBanner)
  .sort((a, b) => (a.priority < b.priority ? -1 : 1))

// GRID APPS IN APPS PAGE
export const gridApps: AppInfoProps[] = appInfoModels.filter(
  x => !topBanners.includes(x)
)
