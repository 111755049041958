import React from "react"
import { Link } from "gatsby"

interface HeaderProps {
  page: string
}

interface HeaderRouter {
  title: string
  color: string
  to: string
}

const Header: React.FunctionComponent<HeaderProps> = props => {
  const headerClass = "hover:bg-gray-200 hover:text-blue-500"

  const routers: Array<HeaderRouter> = [
    { title: "Apps", color: headerClass, to: "/apps" },
    { title: "About", color: headerClass, to: "/about" },
    { title: "Contact Us", color: headerClass, to: "/contact-us" },
    { title: "Privacy", color: headerClass, to: "/privacy" },
    { title: "Terms of Service", color: headerClass, to: "/terms-of-service" },
  ]

  return (
    <header className="flex flex-col xl:flex-row items-center bg-white shadow-xl justify-center lg:justify-between">
      <Link to={"/"}>
        <img
          className="header-img-sm md:header-img-md"
          alt={"logo"}
          src={"/logo.png"}
        />
      </Link>
      <div className="flex">
        {routers.map((r, i) => (
          <Link to={r.to} key={i}>
            <p
              className={`${
                props.page === r.title
                  ? "bg-gray-200 text-blue-500"
                  : "text-gray-800"
              } font-bold w-20 h-20 md:w-40 md:h-40 transform transition duration-500 ease-in-out cursor-pointer flex items-center justify-center text-center text-sm lg:text-2xl ${
                r.color
              }`}
            >
              {r.title}
            </p>
          </Link>
        ))}
      </div>
    </header>
  )
}

export default Header
