import React from "react"

const Footer: React.FunctionComponent = () => {
  return (
    <footer className="flex justify-between bg-gray-100 items-center shadow-xl p-4 mt-56 md:mt-16 lg:mt-10 xl:mt-0">
      <p className="text-gray-500">Brainfull © 2020</p>
      <p className="text-xs text-gray-300">v 1.19</p>
    </footer>
  )
}

export default Footer
